<template>
  <div class="my-register-page">
    <div class="my-container">
      <b-row class="mb-2">
        <b-col>
          <h3>{{ $t("oked") }}</h3>

          <div style="text-align: right">
            <!-- <b-button
          style="width:100px;margin-right:5px"
          @click="$router.go(-1)"
          variant="outline-danger"
        >
          {{ $t("back") }}
        </b-button> -->
            <b-button variant="outline-primary" @click="openModal"> {{ $t('add') }} </b-button>
          </div>
        </b-col>
      </b-row>
      <!-- <b-row class="mb-2">
        <b-col cols="2">
          <custom-select :label="$t('Options')" :valueid="'value'" :valuename="'text'" @input="Refresh" :options="filter.options" v-model="filter.pageSize" />
        </b-col>
      </b-row> -->
      <b-table-simple bordered sticky-header="60vh">
        <b-thead>
          <b-tr variant="primary">
            <b-th style="vertical-align: middle">
              {{ $t("id") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("Code") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("name") }}
            </b-th>
            <!-- <b-th>
              {{ $t("actions") }}
            </b-th> -->
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in Requests" :key="index">
            <b-td class="text-nowrap" style="vertical-align: middle">
              {{ item.id }}
            </b-td>
            <b-td style="vertical-align: middle">
              {{ item.code }}
            </b-td>
            <b-td>
              {{ item.name }}
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-if="SearchLoading">
          <b-tr>
            <b-td colspan="5" class="text-center">
              <b-spinner style="margin-left : 20px" v-if="SearchLoading" small></b-spinner> {{ $t('Loading') }}...
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-if="Requests.length == 0 && !SearchLoading">
          <b-tr>
            <b-td colspan="5" class="text-center">
                {{ $t('NoItems') }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-modal v-model="isOpen" hide-footer size="lg" no-close-on-backdrop hide-header centered>
        <div style="text-align: right; margin-right: 10px; margin-top: -5px; margin-bottom: 5px; border-bottom: 1px solid lightgray">
          <span @click="isOpen = false" style="cursor:pointer; font-size: 30px"> &times; </span>
        </div>
        <div class="mb-2">
          <custom-input :placeholder="$t('search')" v-model="filter1.search" :label="$t('search')"></custom-input>
        </div>
        <b-table :items="OkedList" :current-page="filter1.page" :per-page="filter1.pageSize" bordered :fields="fields" @filtered="onFiltered" :filter="filter1.search">
          <template #cell(isSelected)="{ item }">
            <div class="text-center">
              <b-form-checkbox v-model="item.isSelected">

              </b-form-checkbox>
            </div>
          </template>
        </b-table>

        <div>
          <b-pagination
            v-model="filter1.page"
            :total-rows="filter1.totalRows"
            :per-page="filter1.pageSize"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <b-icon icon="chevron-double-left" size="18" />
            </template>
            <template #next-text>
              <b-icon icon="chevron-double-right" size="18" />
            </template>
          </b-pagination>
        </div>
        <div style="text-align: right"> 
          <b-button variant="outline-success" :disabled="isSave" @click="AddOked">
            <b-spinner style="margin-left : 20px" v-if="isSave" small></b-spinner>  {{ $t('save') }}
          </b-button>
        </div>
      </b-modal>
      <!-- <div>
        <b-pagination
          v-model="filter.page"
          :total-rows="filter.totalRows"
          :per-page="filter.pageSize"
          first-number
          last-number
          @input="Refresh"
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <b-icon icon="chevron-double-left" size="18" />
          </template>
          <template #next-text>
            <b-icon icon="chevron-double-right" size="18" />
          </template>
        </b-pagination>
      </div>  -->
    </div>
  </div>
</template>

<script>
import ContractorService from "@/services/contractor.service";
import customSelect from '../../components/elements/customSelect.vue'
import customInput from '../../components/elements/customInput.vue'
import ManualService from '@/services/manual.service'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BIconEyeFill,
  BPagination,
  BFormCheckbox, BTable, 
} from "bootstrap-vue";
export default {
  data() {
    return {
      Requests: [],
      isOpen : false,
      isSave : false,
      OkedList: [],
      data : {
        okeds : []
      },
      filter1 : {
        page : 1,
        pageSize : 10,
        totalRows : 1,
        search : ''
      },
      filter: {
        page : 1,
        pageSize : 20,
        totalRows : 1,
        level : 5,
      },
      fields : [
        {
          key : 'isSelected',
          label : this.$t('isSelected')
        },
        {
          key : 'code',
          label : this.$t('orderCode')
        },
        {
          key : 'name',
          label : this.$t('name')
        }
      ]
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BTableSimple,
    BIconEyeFill, BPagination, customSelect, BFormCheckbox, BTable, customInput
  },
  created() {
    this.Refresh();
    this.getOkedList()
  },
  methods: {
    Refresh() {
      ContractorService.GetOkeds()
        .then((res) => {
          this.Requests = res.data.okeds
        })
        .catch((error) => {
          this.Loading = false;
          this.$message(error.response.data);
        });
    },
    getOkedList(){
      ManualService.GetOkedAsSelectList(this.filter.level).then(res => {
        var ids = this.Requests.map(item => item.id)
        this.OkedList = []
        for(let i=0; i < res.data.length; i++){
          this.OkedList.push({
            code : res.data[i].orderCode,
            name : res.data[i].text,
            id : res.data[i].value,
            isSelected : ids.includes(res.data[i].value),
          })
        }
        
        this.filter1.totalRows = this.OkedList.length
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filter1.totalRows = filteredItems.length
      this.filter1.page = 1
    },
    openModal(){
      this.isOpen = true
      this.getOkedList()
    },
    AddOked(){
      this.data.okeds = []
      this.OkedList.forEach(item => {
        if(item.isSelected){
          this.data.okeds.push(item)
        }
      })
      this.isSave = true;
      ContractorService.UpdateOkeds(this.data).then(res => {
        this.isSave = false;
        this.isOpen = false
        this.Refresh()
      })
      .catch((error) => {
        this.isSave = false;
        this.$message(error.response.data);
      });
    },
    GetComplaint(item) {
      // ComplaintService.GetByRequestId(item.id)
      // .then((res) => {
      //   // this.Complaint = res.data;

      //     alert('success')
      // })
      // .catch((error) => {
      //   // this.makeToast(error.response.data, "error");
      //   alert('danger')
      // });
      this.$router.push({ name: "EditComplaint", params: { id: item.id } });
    },
    Get(item) {
      this.$router.push({ name: "RequestView", params: { id: item.id } });
    },
    GetIBContractor(item){
      this.$router.push({ name: 'InspectionBookOfContractorEdit', params : { id : item.id }, query : { fromRequest : true }});
    }
  },
};
</script>

<style>
</style>