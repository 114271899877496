import ApiService from './api.service'
const ContractorService =  {
    Get(){
        return ApiService.get(`/Contractor/Get`)
    },
    GetOkeds(){
      return ApiService.get(`/Contractor/GetOkeds`)
    },
    UpdateOkeds(data){
      return ApiService.post(`/Contractor/UpdateOkeds`, data)
    },
    GetSecurityInfo(){
        return ApiService.print(`/Contractor/GetSecurityInfo`)
    }
}
export default ContractorService